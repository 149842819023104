
export const ANALYTICS_EVENTS_TO_SUBSCRIBE = [
  'categoryChanged',
  { event: 'bodyPartClick', labelProperty: (res) => `${res.categoryId}: ${res.bodyPart}` },
  { event: 'bodyPartThumbnailClick', labelProperty: (res) => `${res.categoryId}: ${res.bodyPart}: ${res.style}` },
  { event: 'bodyPartTotalEngaged', labelProperty: (res) => `${res.categoryId}: ${res.bodyPart}` },
  { event: 'patternThumbnailClick', labelProperty: (res) => `${res.categoryId}: ${res.design}` },
  { event: 'patternApplied', labelProperty: (res) => `${res.categoryId}: ${res.design}` },
  { event: 'patternRemoved', labelProperty: (res) => `${res.categoryId}: ${res.design}` },
  { event: 'patternsReset', labelProperty: (res) => `${res.categoryId}: ${res.design}` },
  { event: 'colorThumbnailClick', labelProperty: (res) => `${res.categoryId}: ${res.color}` },
  { event: 'colorApplied', labelProperty: (res) => `${res.categoryId}: ${res.color}` },
  { event: 'colorRemoved', labelProperty: (res) => `${res.categoryId}: ${res.color}` },
  { event: 'colorsReset', labelProperty: (res) => `${res.categoryId}: ${res.color}` },
  { event: 'presetClick', labelProperty: (res) => `${res.categoryId}: ${res.preset}` },
  'presetNextClick',
  'presetPrevClick',
  { event: 'materialThumbnailClick', labelProperty: (res) => `${res.categoryId}: ${res.material}` },
  { event: 'materialApplied', labelProperty: (res) => `${res.categoryId}: ${res.material}` },
  { event: 'materialRemoved', labelProperty: (res) => `${res.categoryId}: ${res.material}` },
  { event: 'materialsReset', labelProperty: (res) => `${res.categoryId}: ${res.material}` },
  { event: 'sfListProductClick', labelProperty: 'productId' },
  { event: 'paginationClick', labelProperty: 'page' },
  { event: 'ymalPageView', labelProperty: (res) => res.productId },
  { event: 'ymalProdcutsFetched', labelProperty: (res) => res.productId },
  { event: 'ymalProdcutsFetchError', labelProperty: (res) => res.productId },
  { event: 'ymalListProductClick', labelProperty: (res) => res.productId },
  { event: 'sfProductAddToBasketClick', labelProperty: (res) => res.productId },
  { event: 'sfProductBuyClick', labelProperty: (res) => res.productId },
  { event: 'ymalProductAddToBasketClick', labelProperty: (res) => res.productId },
  { event: 'ymalProductBuyClick', labelProperty: (res) => res.productId },
  { event: 'brandClick', labelProperty: (res) => `${res.categoryId}: ${res.brand}` },
  { event: 'brandApplied', labelProperty: (res) => `${res.categoryId}: ${res.brand}` },
  { event: 'brandRemoved', labelProperty: (res) => `${res.categoryId}: ${res.brand}` },
  { event: 'textMaterialClick', labelProperty: (res) => `${res.categoryId}: ${res.material}` },
  { event: 'textMaterialApplied', labelProperty: (res) => `${res.categoryId}: ${res.material}` },
  { event: 'textMaterialRemoved', labelProperty: (res) => `${res.categoryId}: ${res.material}` },
  { event: 'occasionClick', labelProperty: (res) => `${res.categoryId}: ${res.occasion}` },
  { event: 'occasionApplied', labelProperty: (res) => `${res.categoryId}: ${res.occasion}` },
  { event: 'occasionRemoved', labelProperty: (res) => `${res.categoryId}: ${res.occasion}` },
  { event: 'vibeClick', labelProperty: (res) => `${res.categoryId}: ${res.vibe}` },
  { event: 'vibeApplied', labelProperty: (res) => `${res.categoryId}: ${res.vibe}` },
  { event: 'vibeRemoved', labelProperty: (res) => `${res.categoryId}: ${res.vibe}` },
  { event: 'sizeClick', labelProperty: (res) => `${res.categoryId}: ${res.size}` },
  { event: 'sizeApplied', labelProperty: (res) => `${res.categoryId}: ${res.size}` },
  { event: 'sizeRemoved', labelProperty: (res) => `${res.categoryId}: ${res.size}` },
  { event: 'discountClick', labelProperty: (res) => `${res.categoryId}: ${res.discount}` },
  { event: 'discountApplied', labelProperty: (res) => `${res.categoryId}: ${res.discount}` },
  { event: 'discountRemoved', labelProperty: (res) => `${res.categoryId}: ${res.discount}` },
  { event: 'shippingOptionClick', labelProperty: (res) => `${res.categoryId}: ${res.shippingOption}` },
  { event: 'shippingOptionApplied', labelProperty: (res) => `${res.categoryId}: ${res.shippingOption}` },
  { event: 'shippingOptionRemoved', labelProperty: (res) => `${res.categoryId}: ${res.shippingOption}` },
  { event: 'newArrivalsClick', labelProperty: (res) => `${res.categoryId}: ${res.newArrivals}` },
  { event: 'newArrivalsApplied', labelProperty: (res) => `${res.categoryId}: ${res.newArrivals}` },
  { event: 'newArrivalsRemoved', labelProperty: (res) => `${res.categoryId}: ${res.newArrivals}` },
  { event: 'washClick', labelProperty: (res) => `${res.categoryId}: ${res.wash}` },
  { event: 'washApplied', labelProperty: (res) => `${res.categoryId}: ${res.wash}` },
  { event: 'washRemoved', labelProperty: (res) => `${res.categoryId}: ${res.wash}` },
  { event: 'sortChanged', labelProperty: (res) => `${res.categoryId}: ${res.sort}` },
  { event: 'sortRemoved', labelProperty: (res) => `${res.categoryId}: ${res.sort}` },
  { event: 'limitChanged', labelProperty: (res) => `${res.categoryId}: ${res.limit}` },
  { event: 'limitRemoved', labelProperty: (res) => `${res.categoryId}: ${res.limit}` },
  'collapsibleMobileOpened',
  'collapsibleMobileClosed',
  'collapsibleClearClicked',
  'collapsibleBodyPartsClearClicked',
  'categoryMenuCategoryClick',
  'categoryMenuCategoryOpenClick',
  'categoryMenuCategoryCloseClick',
  'categoryMenuSubcategoryClick',
];
