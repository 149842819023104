import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import isEqual from 'lodash/isEqual';
import capitalize from 'lodash/capitalize';

const { document } = window;

const CATEGORIES = [
  { label: 'Women', value: 'Women', param: 'categories' },
  { label: 'Men', value: 'Men', param: 'categories' },
  { label: 'K-Beauty', value: 'K-Beauty', param: 'categories' },
  { label: 'Life', value: 'Life', param: 'categories' },
  { label: 'Sale', value: 'sale', param: 'sale' },
];

class Categories extends Widget {
  constructor(params) {
    super(params);

    this.mainElement = document.createElement('ul');
    this.mainElement.className = 'txt-search-categories';
    this.lis = {};
  }

  didUpdate(prevState) {
    if (prevState.filter.gender !== this.state.filter.gender) {
      if (this.lis[prevState.filter.gender]) this.lis[prevState.filter.gender].classList.remove('is-active');
      if (this.lis[this.state.filter.gender]) this.lis[this.state.filter.gender].classList.add('is-active');
    }

    if (prevState.filter.sale !== this.state.filter.sale && this.lis['sale']) {
      this.lis['sale'].classList[(this.state.filter.sale ? 'add' : 'remove')]('is-active');
    } else if (prevState.filter.new !== this.state.filter.new && this.lis['new']) {
      this.lis['new'].classList[(this.state.filter.new ? 'add' : 'remove')]('is-active');
    }
    if (prevState.filter.bestsellers !== this.state.filter.bestsellers && this.lis['bestsellers']) {
      this.lis['bestsellers'].classList[(this.state.filter.bestsellers ? 'add' : 'remove')]('is-active');
    }
    if (!isEqual(prevState.filter.categories, this.state.filter.categories)) {
      (prevState.filter.categories || []).forEach(category => {
        if (
          this.lis[category]
          &&
          !this.state.filter.categories?.includes(this.lis[category])
        ) this.lis[category].classList.remove('is-active');
      });
      (this.state.filter.categories || []).forEach(category => {
        if (this.lis[category]) this.lis[category].classList.add('is-active');
      });
    }
  }

  render() {
    CATEGORIES.forEach(category => {
      const li = document.createElement('li');
      li.innerHTML = category.label;
      if (
        (category.param === 'gender' && this.state.filter.gender === category.value)
        ||
        category.param === 'sale' && this.state.filter.sale
        ||
        category.param === 'new' && this.state.filter.new
        ||
        category.param === 'bestsellers' && this.state.filter.bestsellers
        ||
        category.param === 'categories' && this.state.filter.categories?.includes(category.value)
      ) {
        li.classList.add('is-active')
      }
      li.setAttribute('data-category-value', category.value);
      this.mainElement.appendChild(li);

      li.addEventListener('click', () => {
        if (category.param === 'gender') {
          this.main.updateSearchQuery({ gender: category.value === this.state.filter.gender ? null : category.value });
        }
        else if (category.param === 'categories') {
          this.main.updateSearchQuery({ categories: this.state.filter.categories?.includes(category.value) ? [] : [category.value] });
          EventEmitter.emit('txtSidebarCategoryChanged', category.value);
        }
        else if (category.param === 'sale' || category.param === 'new' || category.param === 'bestsellers') {
          const isActive = !this.state.filter[category.param];
          this.main.updateSearchQuery({ [category.param]: isActive });
          EventEmitter.emit(`txtSidebar${capitalize(category.param)}${isActive ? 'Applied' : 'Removed'}`);
        }
      });

      this.lis[category.value] = li; 
    });
    return this.mainElement;
  }
}

export default (params) => {
  return new Categories(params);
};
