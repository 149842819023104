import Handlebars from 'handlebars';
import take from 'lodash/take';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
import { formatCurrency } from '@yesplz/core';
import unescape from 'lodash/unescape';
import trim from 'lodash/trim';
import Glide from '@glidejs/glide'

import "@glidejs/glide/src/assets/sass/glide.core.scss";
import "@glidejs/glide/src/assets/sass/glide.theme.scss";

const LAYOUT_TEMPLATE = `
<div class="yesplz-text-search-backdrop"></div>
<div class="yesplz-text-search-main">
  <div class="yesplz-text-search-container">
    <button class="yesplz-text-search-close-button"></button>
    <div class="yesplz-text-search-header">
      <div class="yesplz-text-search-bar-container">
        <form class="yesplz-text-search-form">
          <input type="text" placeholder="Search" value="" max />
          <button class="submit" type="submit">Search</button>
          <button id="yesplz-text-search-form-clear" class="clear" type="button">clear</button>
        </form>
      </div>
      <div class="yesplz-text-search-menu-container">
        <div class="yesplz-menu sale-container">
          <ul>
            <li class="sale" data-menu-value="sale">Sale Only</li>
          </ul>
        </div>
        <div class="yesplz-suggestions">
          <div class="yesplz-menu">
            <ul>
              {{#each menuCategories}}
              <li data-menu-value="{{this.value}}">{{this.label}}</li>
              {{/each}}
            </ul>
          </div>
          <h5>Top Suggestions</h5>
          <ul id="yesplz-suggested-queries"></ul>
        </div>
      </div>
    </div>
    <div class="yesplz-text-search-no-results">
      <h4>Popular Searches</h4>
      <ul id="yesplz-popular-queries"></ul>
    </div>
    <div class="yesplz-text-search-products">
      <h3>Recommended product</h3>
      <div id="yesplz-text-search-products"></div>
    </div>
    <div class="yesplz-text-search-footer">
      <button id="see-results">See All Results</button>
    </div>
  </div>
</div>
`;

const SLIDER_TEMPLATE = `
<div class="glide">
  <div class="glide__track" data-glide-el="track">
    <ul class="glide__slides">
    </ul>
  </div>
</div>
`;

const PRODUCT_TEMPLATE = `
  <div class="product-item item product">
    <div class="product-item-info">
        <div class="product-image-block">
            <a class="product-item-photo" href="{{srcUrl}}" target="{{linkTarget}}">
                <img class="photo image lazyloaded" alt="" src="{{frontImg}}">
            </a>
        </div>
        <div class="product-item-details">
            <div class="brand">
                <a href="{{srcUrl}}">{{brand}}</a>
            </div>
            <strong class="product-item-name">
                <a title="" class="product-item-link" href="{{srcUrl}}">{{name}}</a>
            </strong>
            <div class="price-box price-final_price">
              {{#if isSale}}
              <span class="old-price">
                <span class="price-container price-final_price tax weee">
                  <span data-price-type="oldPrice" class="price-wrapper ">
                    <span class="price">{{originalPrice}}</span>
                  </span>
                </span>
              </span>
              {{/if}}
              <span class="normal-price{{#if isSale}} special-price{{/if}}">
                <span class="price-container price-final_price tax weee">
                  <span data-price-type="finalPrice" class="price-wrapper ">
                    <span class="price">{{price}}</span>
                  </span>
                </span>
              </span>
            </div>
        </div>
    </div>
  </div>
`;

const { document } = window;

export class SearchBar extends Widget {
  defaultParams = {
    templates: {
      layout: LAYOUT_TEMPLATE,
      list: SLIDER_TEMPLATE,
      product: PRODUCT_TEMPLATE,
    },
    currency: {},
    productUrl: null,
    searchPageUrl: process.env.PUBLIC_URL + '/search.html',
    onOpened: null,
    onClosed: null,
    onBeforeProductRendered: null,
    onProductsRendered: null,
    mainCategories: [
      { value: 'all', label: 'All' },
      { value: 'WOMEN', label: 'Women' },
      { value: 'MEN', label: 'Men' },
      { value: 'K-BEAUTY', label: 'K-Beauty' },
      { value: 'LIFE', label: 'Life' },
    ],
    overflowContainer: document.body,
    useBrandSuggestions: true,
    categoryParamName: 'topCategory',
  };

  constructor(params) {
    super(params);

    const element = document.createElement('div');
    element.className = (this.params.containerClassName || 'yesplz-text-search') + ' no-results';
    this.mainElement = element;

    this.mainInput = null;
    this.suggestedQueriesContainer = null;

    this.layoutTemplate = Handlebars.compile(this.params.templates.layout || LAYOUT_TEMPLATE);
    this.listTemplate = Handlebars.compile(this.params.templates.list || SLIDER_TEMPLATE);
    this.productTemplate = Handlebars.compile(this.params.templates.product || PRODUCT_TEMPLATE);

    this.topCategory = null;
    this.sale = false;

    this.menuElements = {};

    this.throttlingTimeout = null;
    this.suggestionPromise = null;
    this.searchPromise = null;

    this.isPopularQueriesLoaded = false;

    this.glide = null;
  }

  didMount() {
    this.renderLayout();
    this.suggestedQueriesContainer = this.mainElement.querySelector('#yesplz-suggested-queries');
    this.popularQueriesContainer = this.mainElement.querySelector('#yesplz-popular-queries');
    this.productsContainer = this.mainElement.querySelector('#yesplz-text-search-products');
    this.addEventListeners();

    // setTimeout(() => {
    //   this.loadPopularQueries();
    //   this.open();
    //   this.mainInput.value = 'Dunst';
    //   this.loadResults();
    // }, 500);
    this.loadPopularQueries();
  }

  selectMainCategory() {
  }

  addEventListeners() {
    const maxAttempts = 10;
    let attempts = 0;
    const interval = setInterval(() => {
      attempts++;
      const startInput = document.querySelector(this.params.startInput);
      if (startInput) {
        startInput.addEventListener('mouseover', () => {
          this.loadPopularQueries();
        });
        startInput.addEventListener('click', this.open);
        clearInterval(interval);
      }
      if (attempts >= maxAttempts) {
        clearInterval(interval);
      }
    }, 100);

    const closeButton = this.mainElement.querySelector('.yesplz-text-search-close-button');
    if (closeButton) {
      closeButton.addEventListener('click', this.close);
    }

    const backdrop = this.mainElement.querySelector('.yesplz-text-search-backdrop');
    if (backdrop) {
      backdrop.addEventListener('click', this.close);
    }

    const mainInput = this.mainElement.querySelector('form input');
    if (mainInput) {
      mainInput.addEventListener('keyup', this.handleKeyUp);
    }
    this.mainInput = mainInput;
    
    const menuItems = this.mainElement.querySelectorAll('li[data-menu-value]');
    if (menuItems) {
      menuItems.forEach(item => {
        const name = item.getAttribute('data-menu-value');
        this.menuElements[name] = item;
        item.addEventListener('click', this.handleMenuClick);
      });
    }

    const form = this.mainElement.querySelector('form');
    if (form) {
      form.addEventListener('submit', (e) => {
        e.preventDefault();
        EventEmitter.emit('txtSearchBarFormSubmit');
        this.goToResultsPage();
      });
    }

    const clearButton = form.querySelector('#yesplz-text-search-form-clear');
    clearButton.addEventListener('click', () => {
      this.reset();
    });
    this.clearButton = clearButton;

    const seeResults = this.mainElement.querySelector('#see-results');
    if (seeResults) {
      seeResults.addEventListener('click', (e) => {
        e.preventDefault();
        EventEmitter.emit('txtSearchBarSeeAllClick');
        this.goToResultsPage();
      });
    }
  }

  formatCurrency(number) {
    return formatCurrency(number,  this.params.currency);
  }

  goToResultsPage() {
    if (!this.mainInput.value) return;

    EventEmitter.emit('txtSearchBarQuery', this.mainInput.value);
    window.location.href= `${this.params.searchPageUrl}?query=${encodeURIComponent(this.mainInput.value)}${this.topCategory ? '&' + this.params.categoryParamName + '=' + this.topCategory : ''}&sale=${this.sale || ''}&sort=popular`
  }

  open = () => {
    this.params.overflowContainer.style.overflow = 'hidden';
    this.params.overflowContainer.style.height = '100vh';

    this.mainElement.classList.add('is-active');
    this.mainInput.focus();

    if (this.mainInput.value) {
      this.loadResults();
    }

    if (typeof this.params.onOpened === 'function') {
      this.params.onOpened();
    }

    EventEmitter.emit('txtSearchBarOpened');
  }

  close = () => {
    this.params.overflowContainer.style.overflow = '';
    this.params.overflowContainer.style.height = '';

    this.mainElement.classList.remove('is-active');
    this.reset();

    if (typeof this.params.onClosed === 'function') {
      this.params.onClosed();
    }

    EventEmitter.emit('txtSearchBarClosed');
  }

  reset = () => {
    this.mainInput.value = '';
    this.productsContainer.innerHTML = '';
    this.mainElement.classList.add('no-results');
    this.mainElement.classList.remove('empty-results');
    this.clearButton.classList.remove('is-active');
  }

  handleMenuClick = (e) => {
    const name = e.target.getAttribute('data-menu-value');
    if (name === 'sale') {
      if (this.sale) {
        this.menuElements['sale'].classList.remove('is-active');
        this.sale = false;
        EventEmitter.emit('txtSearchBarSaleOnlyOn');
      }
      else {
        this.menuElements['sale'].classList.add('is-active');
        this.sale = true;
        EventEmitter.emit('txtSearchBarSaleOnlyOff');
      }
    }
    else {
      if (this.topCategory === name) {
        // Do nothing for now!
      }
      else {
        this.topCategory = name === 'all' ? null : name;
        Object.entries(this.menuElements).forEach(([name, menuElement]) => {
          if (name === 'sale') return;
          menuElement.classList.remove('is-active');
        });
        this.menuElements[name].classList.add('is-active');
      }
    }

    if (typeof this.params.onCategoryChange === 'function') {
      this.params.onCategoryChange(this.topCategory)
    }

    if (this.mainInput.value)
      this.loadResults();
  }

  handleKeyUp = () => {
    if (this.throttlingTimeout) {
      clearTimeout(this.throttlingTimeout);
    }
    this.throttlingTimeout = setTimeout(() => {
      this.loadResults();
    }, 200);
  }

  loadResults() {
    const query = this.mainInput.value;
    if (query.length > 0) {
      this.clearButton.classList.add('is-active');
      this.mainElement.classList.remove('no-results');
      const suggestionPromise = Promise.all([
        (
          this.params.useBrandSuggestions
            ? this.main.fetchBrandsSuggestions(query)
            : Promise.resolve([])
        ),
        this.main.fetchTextAutocomplete({
          query,
          [this.params.categoryParamName]: this.topCategory,
          sale: this.sale,
        }),
      ]);
      this.suggestionPromise = suggestionPromise;
      suggestionPromise
        .then(([brands, texts]) => {
          const suggestionsMax = window.innerWidth <= 768 ? 4 : 8;
          const brandSuggestions = take(brands, texts.length > (suggestionsMax/2) ? (suggestionsMax/2) : suggestionsMax - texts.length).map(brand => ({ suggestion: brand.brandName, url: brand.brandUrl, type: 'brand' }));
          return [
            ...brandSuggestions,
            ...(brandSuggestions.length > 0 ? [{ suggestion: ' ', type: 'separator' }] : []),
            ...take(texts, brandSuggestions.length > (suggestionsMax/2) ? (suggestionsMax/2) : suggestionsMax - brandSuggestions.length)
          ];
        })
        .then(suggested => {
          if (this.suggestionPromise === suggestionPromise) {
            this.renderSuggestedQueries(suggested, query);
          }
        })
        .catch(error => {
          console.error('text suggestion error', error);
        });

      const searchPromise = this.main.fetchTextSearch({
        query,
        sort: 'popular',
        [this.params.categoryParamName]: this.topCategory,
        sale: this.sale,
        limit: 5,
        preview: true,
      })
      this.searchPromise = searchPromise;
      searchPromise
        .then(data => {
          if (this.searchPromise === searchPromise) {
            this.productsContainer.style.display = 'none';

            window.requestAnimationFrame(() => {
              this.productsContainer.innerHTML = this.listTemplate();
              const listContainer = this.productsContainer.querySelector('ul');
              data?.results?.forEach(({ product }) => {
                this.renderProduct(product, listContainer);
              });

              if (typeof this.params.onProductsRendered === 'function') {
                this.params.onProductsRendered(data.results);
              }

              if (data?.results && data?.results?.length > 0) {
                this.mainElement.classList.remove('empty-results');
                if (window.innerWidth < 768) {
                  setTimeout(() => {
                    this.productsContainer.style.display = 'block';
                    this.glide = new Glide('.glide', {
                      type: data?.results?.length > 2 ? 'carousel' : 'slider',
                      startAt: 0,
                      perView: window.innerWidth >= 768 ? 5 : 2.3,
                      perTouch: window.innerWidth >= 768 ? 5 : 2,
                    }).mount();
                  }, 100);
                }
                else {
                  this.productsContainer.style.display = 'block';
                }
              }
              else {
                this.mainElement.classList.add('empty-results');
              }
            });
          }
        })
        .catch(error => {
          console.error('text search error', error);
        });
    }
    else {
      this.reset();
    }
  }

  addEventListenersToList(container) {
    container.querySelectorAll('li').forEach(li => {
      li.addEventListener('click', () => {
        const type = li.getAttribute('data-type');
        const suggested = trim(unescape(li.innerHTML.replaceAll('<strong>', '').replaceAll('</strong>', '')));
        EventEmitter.emit(
          type === 'popular'
            ? 'txtSearchBarPopularSearchesClick'
            : type === 'brand'
              ? 'txtSearchBarBrandClick'
              : 'txtSearchBarSuggestionClick',
          suggested
        );
        if (type === 'brand') {
          const url = li.getAttribute('data-url');
          if (url) window.location.href = url;

          return;
        }
        this.mainInput.value = suggested;
        this.goToResultsPage();
      });
    });
  }

  async loadPopularQueries() {
    if (this.isPopularQueriesLoaded) return;

    let popularQueries = [];
    try {
      popularQueries = await this.main.fetchPopularQueries({
        [this.params.categoryParamName]: this.topCategory,
        sale: this.sale,
      });
    } catch (error) {
      console.error('text popular queries error', error);
    }
    this.renderPopularQueries(popularQueries);
    this.isPopularQueriesLoaded = true;
  }

  getProductUrl(product) {
    if (typeof this.params.productUrl === 'function') {
      return this.params.productUrl(product);
    }
    return product.productUrl;
  }

  renderPopularQueries(populars = []) {
    const container = this.popularQueriesContainer;
    container.innerHTML = populars.map(popular => {
      return `<li data-type="popular">${popular.suggestion}</li>`;
    }).join('');

    this.addEventListenersToList(container);
  }

  renderSuggestedQueries(suggested = [], query = null) {
    const container = this.suggestedQueriesContainer;
    container.innerHTML = suggested.map(({ suggestion, url, type }) => {
      const suggestionWords = suggestion.split(' ');
      if (query) {
        const queryWords = query.split(' ');
        suggestionWords.forEach((suggestionWord, index) => {
          if (queryWords.includes(suggestionWord)) {
            suggestionWords[index] = `<strong>${suggestionWord}</strong>`;
          }
        });
      }
      return `<li${
        type ? ` class="suggestion-type-${type}" data-type="${type}"` : ''
      }${
        url ? ` data-url="${url}"` : ''
      }>${suggestionWords.join(' ')}</li>`;
    }).join('');

    this.addEventListenersToList(container);
  }

  renderProduct(product, listContainer) {
    let productHtml = this.productTemplate({
      ...product,
      srcUrl: this.getProductUrl(product),
      frontImg: product.frontImgSrc,
      name: product.productName,
      brand: product.brand || product.brandName,
      isSale: !!(product.originalPrice && product.originalPrice !== product.salePrice),
      originalPrice: this.formatCurrency(product.originalPrice),
      price: this.formatCurrency(product.salePrice),
    });

    if (typeof this.params.onBeforeProductRendered === 'function') {
      productHtml = this.params.onBeforeProductRendered(productHtml, product);
    }

    const html = `<li class="glide__slide product-item">${productHtml}</li>`;
    listContainer.insertAdjacentHTML("beforeend", html);
    listContainer.lastElementChild.querySelectorAll('a')?.forEach(a => {
      a.addEventListener('click', () => {
        EventEmitter.emit('txtSearchBarProductClick', product.productId);
      });
    });
  }

  renderLayout() {
    this.mainElement.innerHTML = this.layoutTemplate({
      mainCategories: this.params.mainCategories,
    });
    if (typeof this.params.onRendered === 'function') {
      window.requestAnimationFrame(() => {
        this.params.onRendered();
      });
    }
  }

  render() {
    return this.mainElement;
  }
}

export default (params) => {
  return new SearchBar(params);
};
