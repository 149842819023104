import Widget from '@yesplz/textsearch/src/modules/Widget';
import EventEmitter from '@yesplz/visualfilter/src/modules/EventEmitter';
import difference from 'lodash/difference';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import categoriesNumIdsMapping from '@yesplz/core/src/wconcept/categories-mapping';
import { findLabelByLng } from '@yesplz/core';
import take from 'lodash/take';

const { document } = window;

export class DynamicTreeViewMenu extends Widget {
  defaultParams = {
    items: null,
    displayAllForSubcategories: false,
    valueName: 'categories',
    filterParam: 'retailerCategory',
    templates: {},
  };

  constructor(params) {
    params.items = [];

    super(params);

    const element = document.createElement('div');
    element.className = this.params.containerClassName || 'yesplz-tree-view';
    this.mainElement = element;

    this.visualFilterContainer = document.querySelector(
      this.params.visualFilterContainer
    );

    this.itemsIndex = {};

    this.lastTopCategoryId = null;
    this.fallBackCategory = null;
  }

  didMount() {
    const { filterParam } = this.params;
    this.mainElement.classList.add('dynamic-tree-menu');

    if (this.state.search?.filter?.[filterParam]) {
      this.prepareAndRenderItems(this.state.search?.filter?.[filterParam]);
    }
  }

  get currentCategoryId() {
    const { valueName } = this.params;
    const currentValue = this.state.filter[valueName] || [];
    const categoryId = Array.isArray(currentValue) ? currentValue[0] : currentValue;

    if (!categoryId) return null;
    
    const item = this.itemsIndex[categoryId];
    return item.isSubcategory ? item.parentCategoryId : categoryId;
  }

  didUpdate(prevState) {
    const { filterParam, valueName } = this.params;

    if (!isEqual(this.state.search?.filter?.[filterParam], prevState.search?.filter?.[filterParam])) {
      this.prepareAndRenderItems(this.state.search?.filter?.[filterParam]);
    }
    if (!isEqual(this.state.filter[valueName], prevState.filter[valueName])) {
      this.deactivateAll();
      this.lastTopCategoryId = this.currentCategoryId ? this.findTopCategoryId(this.currentCategoryId) : this.lastTopCategoryId;
      if (this.currentCategoryId) this.updateSelected(this.currentCategoryId);


      const subcategory = this.state.filter[valueName] || [];
      const prevSubcategory = prevState.filter[valueName] || [];

      const selected = difference(subcategory, prevSubcategory);
      const deselected = difference(prevSubcategory, subcategory);

      this.updateSelectedSubcategories(selected, deselected);
    }
  }

  get indexId() {
    const { filterBy } = this.params;
    const value = get(this.state.filter, filterBy);
    return Array.isArray(value) ? value.join(',') : value;
  }

  findTopCategoryId(categoryId) {
    if (this.itemsIndex[categoryId]?.parentCategoryId) {
      return this.findTopCategoryId(this.itemsIndex[categoryId].parentCategoryId);
    }
    else {
      return categoryId;
    }
  }

  prepareAndRenderItems(items = []) {
    const { valueName } = this.params;
    this.renderItems(this.prepareCategories(items));
    this.updateSelected(this.currentCategoryId);
    const currentValues = this.state.filter[valueName]
      ? Array.isArray(this.state.filter[valueName])
        ? this.state.filter[valueName]
        : [this.state.filter[valueName]]
      : [];
    this.updateSelectedSubcategories(currentValues, []);
  }

  formatItems(items, level = 0, additional = {}) {
    return items.map(item => {
      const newItem = {
        label: item.categoryName,
        categoryId: item.fullPath,
        level,
        ...additional,
      };
      if (item.children?.length && level <= 3) {
        const newLevel = level + 1;
        const isChildrenHaveChildren = !!find(item.children, (i) => i?.children?.length);
        newItem.children = this.formatItems(
          item.children,
          newLevel,
          {
            parentCategoryId: item.fullPath,
            ...(!isChildrenHaveChildren ? { isSubcategory: true } : {}),
          }
        );
        if (!isChildrenHaveChildren) newItem.isSubcategoryParent = true;
      }
      this.itemsIndex[newItem.categoryId] = newItem;
      return newItem;
    }).filter(Boolean)
  }

  prepareCategories(baseItems) {
    return this.formatItems(baseItems);
  }

  activateItemAndParents(currentLi, isParent = false) {
    if (!currentLi) return;

    currentLi.classList.add(isParent ? 'active-parent' : 'active');
    currentLi.classList.add('opened');

    const listParent = currentLi.parentElement.parentElement;
    if (listParent.tagName === 'LI') {
      this.activateItemAndParents(listParent, true);
    }
  }

  closeAllOpened() {
    const oldOpened = this.mainElement.querySelectorAll('li.opened');
    oldOpened.forEach(li => {
      li.classList.remove('opened');
    });
  }

  deactivateAll() {
    const oldActives = this.mainElement.querySelectorAll('li.active:not(.subcategory)');
    oldActives.forEach(li => {
      li.classList.remove('active');
    });

    const topActive = this.mainElement.querySelector('.top-level a.active');
    if (topActive) topActive.classList.remove('active');
  }

  updateSelected(categoryId) {
    if (!categoryId && window.innerWidth < 768) {
      const query = !this.lastTopCategoryId ? `ul > li:first-child` : `[data-tree-item-catid="${this.lastTopCategoryId}"]`;
      const li = this.mainElement.querySelector(query);
      if (li) li.classList.add('opened');
      return;
    }

    const query = `[data-tree-item-catid="${categoryId}"]`;
    const li = this.mainElement.querySelector(query);

    if (!li) return;

    this.activateItemAndParents(li);

    const { valueName } = this.params;
    if (isEqual(this.state.filter[valueName], [categoryId])) {
      const allItem = li.querySelector(query);
      if (allItem) allItem.classList.add('active');
    }

    const topCategoryId = categoryId.split('/')[0]
    const topToActivate = this.mainElement.querySelector(`.top-level a[data-main-category-id="${topCategoryId}"]`);
    if (topToActivate) topToActivate.classList.add('active');
  }

  updateSelectedSubcategories(selected = [], deselected = []) {
    selected.forEach(categoryId => {
      const query = `.subcategory[data-tree-item-catid="${categoryId}"]`;
      const li = this.mainElement.querySelector(query);
      if (li) li.classList.add('active');
    });
    deselected.forEach(categoryId => {
      const query = `.subcategory[data-tree-item-catid="${categoryId}"]`;
      const li = this.mainElement.querySelector(query);
      if (li) li.classList.remove('active');
    });
  }

  handleItemClick = (e, item) => {
    e.stopPropagation();
    if (item.isDisabled) return;

    const { valueName } = this.params;
    if (
      this.currentCategoryId === item.categoryId
      &&
      !(item.isAllItem && this.state.filter[valueName]?.length > 1)
    ) {
      this.deselectCurrentCategory();
    }
    else {
      if (item.level === 1 || item.level === 2) {
        const li = e.target.parentNode;
        li.parentNode.querySelectorAll(`li.opened:not([data-tree-item-catid="${item.categoryId}"])`).forEach(li => {
          li.classList.remove('opened');
        });
      }

      this.selectNewCategory(item);
    }
  }

  deselectCurrentCategory() {
    const { valueName } = this.params;
    if (this.fallBackCategory) {
      const topCategory = this.findTopCategoryId(this.fallBackCategory);
      this.main.updateSearchQuery({ [valueName]: [topCategory] });
      return;
    }

    const categories = [];

    const categoryIdSplits = this.currentCategoryId.split('/');
    const newCategoryId = take(categoryIdSplits, categoryIdSplits.length - 1).join('/');
    categories.push(newCategoryId);

    this.main.updateSearchQuery({ [valueName]: categories });
  }

  selectNewCategory(item) {
    const { valueName } = this.params;
    if (item.isSubcategory) {
      const currentCategories = this.state.filter.categories;
      // Delete parent categories
      let subcategories = [...(currentCategories || [])].filter((categoryId) => {
        return this.itemsIndex[categoryId].isSubcategory;
      });
      if (subcategories.includes(item.categoryId)) {
        subcategories = subcategories.filter(c => c !== item.categoryId);
        if (subcategories.length === 0 && item.parentCategoryId) {
          subcategories = [item.parentCategoryId];
        }
      }
      else {
        subcategories.push(item.categoryId);
      }
      this.main.updateSearchQuery({ [valueName]: subcategories });
    }
    else {
      this.main.updateSearchQuery({ [valueName]: [item.categoryId] });
    }
  }

  handleTogglerClick = (e, item) => {
    if (item.isDisabled) return;

    e.stopPropagation();
    const li = e.target.parentNode;

    if (item.level === 1) {
      li.parentNode.querySelectorAll(`li.opened:not([data-tree-item-catid="${item.categoryId}"])`).forEach(li => {
        li.classList.remove('opened');
      });
    }

    li.classList.toggle('opened');

    if (li.classList.contains('opened')) {
      EventEmitter.emit('categoryMenuCategoryCloseClick', item.categoryId);
    }
    else {
      EventEmitter.emit('categoryMenuCategoryOpenClick', item.categoryId);
    }
  }

  buildUrlByCategoryId(categoryId, isSale = false) {
    if (!categoriesNumIdsMapping[categoryId]) return '';

    const rewriteUrl = categoriesNumIdsMapping[categoryId].rewriteUrl;
    if (rewriteUrl) {
      return `/${rewriteUrl}`;
    }

    const numId = categoriesNumIdsMapping[categoryId].id;

    return `/plp/${isSale ? 'sales' : 'plp'}/index/category/${numId}`;
  }

  makeLabel(label) {
    if (!label) return '';
    return label
      .replace(/\//g, ' ')
      .replace(/-/g, ' ')
      .split(' ')
      .map(capitalize)
      .join(' ');
  }

  renderTree(items, parent = null) {
    const ul = document.createElement('ul');
    const allItem = parent && this.params.useAllItem ? { label: `All ${parent.label}`, categoryId: parent.categoryId, isAllItem: true } : null;
    [...(allItem ? [allItem] : []), ...items].forEach(item => {
      if (this.state.filter.topCategory && parent === null && this.state.filter.topCategory !== item.categoryId) {
        return;
      }

      const li = document.createElement('li');

      let liHtml = `<span>${this.makeLabel(findLabelByLng(item.label && item.label, this.state.config.lng))}</span>`;    
      if (Array.isArray(item.children) && item.children.length) {
        liHtml += `<i class="toggler"></i>`;
        li.classList.add('has-children');
      }
      if (item.isAllItem) {
        li.classList.add('all-item');
      }
      li.innerHTML = liHtml;
      if (item.categoryId) {
        li.setAttribute('data-tree-item-catid', item.categoryId);
      }
      if (
        Array.isArray(item.children) && item.children.length
      ) {
        li.appendChild(this.renderTree(item.children, item));
      }
      if (item.isSubcategoryParent) {
        li.classList.add('subcategories');
      }
      else if (item.isSubcategory) {
        li.classList.add('subcategory');
      }
      if (item.isDisabled) {
        li.classList.add('disabled');
      }
      ul.appendChild(li);

      li.querySelector('span')?.addEventListener('click', (e) => this.handleItemClick(e, item));
      li.querySelector('i.toggler')?.addEventListener('click', (e) => this.handleTogglerClick(e, item));
    });

    return ul;
  }

  cutAfterForthLevel(items, level = 0) {
    return items.map(item => {
      const newItem = { ...item };
      if (level >= 5) {
        delete newItem.children;
      }
      else if (Array.isArray(item.children) && item.children.length) {
        newItem.children = this.cutAfterForthLevel(item.children, level + 1);
      }
      return newItem;
    });
  }

  renderItems(items) {
    this.mainElement.innerHTML = '';
    this.mainElement.appendChild(this.renderTree(items));
  }

  render() {
    return this.mainElement;
  }
}

export default (params) => {
  return new DynamicTreeViewMenu(params);
};
