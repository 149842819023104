import Cookies from 'js-cookie';
import openerImage from './assets/images/ai-stylist-opener.png';
import { getYPID } from '@yesplz/visualfilter/src/modules/analytics';
import './AiStylist.scss';

const ypid = getYPID();
const state = JSON.parse(window.localStorage.getItem('yesplz-stylist-store')) || {
  isOpen: false,
  messages: [],
};

export class AiStylist {
  constructor(params = {}) {
    let {
      opener,
      useFloatingButton = false,
      url,
      navigateOnProductClick = true,
    } = params
    this.opener = opener;
    this.frame = null;
    this.buildProductUrl = params?.buildProductUrl || null;
    this.navigateOnProductClick = navigateOnProductClick;

    if (typeof opener === 'string') {
      this.opener = document.querySelector(opener);
    }

    this.url = url || process.env.STYLIST_EMBEDDED_URL + process.env.RETAILER || 'https://wconcept-stylist.dev.yesplz.ai';

    if (!opener && !useFloatingButton) {
      throw new Error('opener - param was not provided. Impossible to assign an open event');
    }

    this.insertIframe();
    if (useFloatingButton) {
      this.insertFloatingButton();
    }

    this.eventListers = {};

    setTimeout(() => {
      this.assignEvents();
      if (state.isOpen) {
        this.open();
      }
    }, 100);
  }

  assignEvents() {
    if (this.opener.addEventListener)
      this.opener.addEventListener('click', this.open);

    window.addEventListener("message", this.handleMessageEvent, false);
  }

  insertFloatingButton() {
    const button = document.createElement('button');
    button.className = 'yesplz-stylist-opener';
    button.id = 'yesplz-stylist-opener';
    const image = document.createElement('img');
    image.src = openerImage;
    button.appendChild(image);
    document.body.appendChild(button);

    this.opener = button;
  }

  insertIframe() {
    const frame = document.createElement('iframe');
    frame.src = this.url;
    frame.width = 411;
    frame.height = 780;
    frame.id = 'yesplz-iframe';
    frame.className = 'yesplz-iframe';
    document.body.appendChild(frame);
    this.frame = frame;
  }

  handleMessageEvent = (event) => {
    if (
      ["https://wconcept-stylist.dev.yesplz.ai", 'https://sf-app-stylist.dev.yesplz.ai', 'http://localhost:5173'].includes(event.origin) === false
    ) return;

    try {
      const message = event.data;
      if (message.type === 'ready') {
        this.frame.contentWindow.postMessage({
          type: 'init',
          data: {
            ypid,
            messages: state.messages,
            locationPathname: state.locationPathname,
          },
        }, '*')
      }
      else if (message.type === 'close') {
        this.close();
        this.updateStore({
          isOpen: false,
          isMinimized: false,
        });
      }
      else if (message.type === 'minimize') {
        this.minimize();
        this.updateStore({
          isOpen: true,
          isMinimized: true,
        });
      }
      else if (message.type === 'maximize') {
        this.maximize();
        this.updateStore({
          isOpen: true,
          isMinimized: false,
        });
      }
      else if (
        message.type === 'hybridSearchProductClick'
        && this.navigateOnProductClick
        && message.productId
      ) {
        const productUrl = typeof this.buildProductUrl === 'function'
          ? this.buildProductUrl(message)
          : message.productUrl || `product.html?productId=${message.productId}`;
        window.location.href = productUrl;
      }
      else if (
        message.type === 'productClick'
        && message.productId
        && this.navigateOnProductClick
      ) {
        try {
          this.updateStore({
            isOpen: true,
            isMinimized: false,
            messages: message.messages,
            locationPathname: message.locationPathname,
          });
          const productUrl = typeof this.buildProductUrl === 'function'
            ? this.buildProductUrl(message)
            : message.productUrl || `product.html?productId=${message.productId}`;
          window.location.href = productUrl;
        }
        catch (e) {
          console.log('Error on productClick event:')
          console.error(e);
        }
      }
      else if (message.type === 'addToCartClick') {
        if (message.productId) {
          this.notifyEventListeners('addToCartClick', {
            productId: message.productId,
          });
        }
      }
      else if (message.type === 'productLiked') {
        if (message.productId) {
          this.notifyEventListeners('productLiked', {
            productId: message.productId,
          });
        }
      }
      else if (message.type === 'productDisliked') {
        if (message.productId) {
          this.notifyEventListeners('productDisliked', {
            productId: message.productId,
          });
        }
      }
    }
    catch {
      return;
    }
  }

  updateStore(data) {
    window.localStorage.setItem('yesplz-stylist-store', JSON.stringify(data));
  }

  handleOutsideClick = (e) => {
    if (
      this.frame.classList.contains('is-open')
      && !this.frame.contains(e.target)
      && !this.opener.contains(e.target)
    ) {
      this.close();
    }
  }

  open = () => {
    if (this.frame) {
      this.frame.classList.add('is-open');
      this.frame.classList.remove('is-minimized');
      this.opener.classList.add('hidden');
      document.body.classList.add('yesplz-stylist-open');

      // Detect click outside of iframe
      document.addEventListener('click', this.handleOutsideClick);
    }
  }

  close = () => {
    if (this.frame) {
      this.frame.classList.remove('is-open');
      this.frame.classList.remove('is-minimized');
      this.opener.classList.remove('hidden');
      document.body.classList.remove('yesplz-stylist-open');

      document.removeEventListener('click', this.handleOutsideClick);
    }
  }

  minimize = () => {
    if (this.frame) {
      this.frame.classList.add('is-minimized');
      document.body.classList.remove('yesplz-stylist-open');
    }
  }

  maximize = () => {
    if (this.frame) {
      this.frame.classList.remove('is-minimized');
      document.body.classList.add('yesplz-stylist-open');
    }
  }

  notifyEventListeners(eventName, data) {
    if (this.eventListers[eventName]) {
      this.eventListers[eventName].forEach(cb => cb(data));
    }
  }

  addEventListener(eventName, callback) {
    if (typeof callback !== 'function') {
      throw new Error('callback is not a function');
    }

    if (this.eventListers[eventName]) {
      this.eventListers[eventName].push(callback);
    }
    else {
      this.eventListers[eventName] = [callback];
    }
  }

  removeEventListener(eventName, callback) {
    if (this.eventListers[eventName]) {
      this.eventListers[eventName] = this.eventListers[eventName].filter(cb => cb !== callback);
    }
  }
}

export default (params) => {
  return new AiStylist(params || {});
}
