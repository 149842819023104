import upperFirst from 'lodash/upperFirst';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import closeSvg from '@yesplz/core-web/assets/svg/close-icon-black.svg';
import resetIconSvg from '@yesplz/core-models/assets/svg/reset-icon.svg';
import { findLabelByLng, formatCurrency } from '@yesplz/core';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';

const { document } = window;

export const WIDGETS_MAP = {
  'internalCategories': 'InternalCategoriesFilter',
  'categories': 'Categories',
  'dynamicTreeMenu': 'DynamicTreeViewMenu',
  'brand': 'BrandFilter',
  'colors': 'ColorFilter',
  'textMaterials': 'ListFilter',
  'price': 'PriceRangeFilter',
  'size': 'ListFilter',
  'discounts': 'ListFilter',
  'shipping': 'ListFilter',
  'newArrivals': 'ListFilter',
  'occasions': 'ListFilter',
  'vibes': 'ListFilter',
  'chips': 'Chips',
  'bodyParts': 'BodyPartsList',
  'patterns': 'PatternsFilter',
};

export class CollapsibleFilters extends Widget {
  defaultParams = {
    filters: ['categories', 'brand', 'colors', 'price'],
    stickyHeaders: ['brand'],
    filtersOpenByDefault: ['categories'],
    filtersOpenByDefaultMobile: ['categories'],
    filterLabels: {
      'categories': [
        { lng: 'en', label: 'Category' },
        { lng: 'ko', label: '카테고리' },
      ],
      'internalCategories': [
        { lng: 'en', label: 'Categories' },
        { lng: 'ko', label: '카테고리' },
      ],
      'dynamicTreeMenu': [
        { lng: 'en', label: 'Categories' },
        { lng: 'ko', label: '카테고리' },
      ],
      'colors': [
        { lng: 'en', label: 'Color' },
        { lng: 'ko', label: '색상' },
      ],
      'brand': [
        { lng: 'en', label: 'Brand' },
        { lng: 'ko', label: '브랜드' },
      ],
      'textMaterials': [
        { lng: 'en', label: 'Material' },
        { lng: 'ko', label: '소재' },
      ],
      'price': [
        { lng: 'en', label: 'Price' },
        { lng: 'ko', label: '가격' },
      ],
      'size': [
        { lng: 'en', label: 'Size' },
        { lng: 'ko', label: '사이즈' },
      ],
      'discounts': [
        { lng: 'en', label: 'Discount' },
        { lng: 'ko', label: 'Discount' },
      ],
      'shipping': [
        { lng: 'en', label: 'Shipping Options' },
        { lng: 'ko', label: 'Shipping Options' },
      ],
      'newArrivals': [
        { lng: 'en', label: 'New Arrivals' },
        { lng: 'ko', label: 'New Arrivals' },
      ],
      'occasions': [
        { lng: 'en', label: 'Occasions' },
        { lng: 'ko', label: 'Occasions' },
      ],
      'vibes': [
        { lng: 'en', label: 'Vibes' },
        { lng: 'ko', label: 'Vibes' },
      ],
      'patterns': [
        { lng: 'en', label: 'Patterns' },
        { lng: 'ko', label: 'Patterns' },
      ],
      'bodyParts': [
        { lng: 'en', label: 'Design' },
        { lng: 'ko', label: 'Design' },
      ],
    },
    useHeader: true,
    useFooter: true,
    isFoldable: true,
    isOpenInitialy: false,
    categoriesFilterParams: {},
    colorsFilterParams: {
      resetImage: resetIconSvg,
      displayLabels: true,
    },
    brandFilterParams: {
      displaySearchBar: true,
      displayHeaders: true,
      displaySelectedBrands: false,
    },
    textMaterialsFilterParams: {
      filterParamName: 'materials',
      listParamName: 'materials',
      title: 'Materials',
    },
    sizeFilterParams: {
      filterParamName: 'sizes',
      listParamName: 'sizes',
      title: 'Sizes',
    },
    discountsFilterParams: {
      filterParamName: 'discount',
      listParamName: 'discountRate',
      title: 'Discounts',
      listMiddleware: (data) => {
        if (data) {
          const items = [
            { label: 'up to 20% off', value: '-20', min: 0,  max: 20 },
            { label: '20%-30% off', value: '20-30', min: 21, max: 30 },
            { label: '30%-50% off', value: '30-50', min: 31, max: 50 },
            { label: '50%-75%+ off', value: '50-',  min: 51, max: 100 },
          ];
          items.forEach(item => {
            item.isActive = item.max <= Math.floor(data.max) || item.min <= Math.floor(data.max);
          });
          return items;
        }
        return [];
      },
    },
    shippingFilterParams: {
      filterParamName: 'shipping',
      listParamName: 'shippingZone',
      title: 'Shipping Options',
      listMiddleware: (data) => {
        if (data) {
          const availableOptions = Object.values(data).map(({ value }) => value);
          const items = [
            { label: 'Priority', value: 'PRIORITY' },
            { label: 'Standard', value: 'OVERSEAS_STANDARD' },
            { label: 'Pre-order', value: 'OVERSEAS_PRE_ORDER' },
            { label: 'Made to order', value: 'OVERSEAS_MADETOORDER' },
          ];
          items.forEach(item => {
            item.isActive = availableOptions.includes(item.value);
          });
          return items;
        }
        return [];
      },
    },
    newArrivalsFilterParams: {
      filterParamName: 'newArrivals',
      listParamName: 'newArrivals',
      title: 'New Arrivals',
      items: [
        { label: '1 day', value: '1', isActive: true },
        { label: '7 days', value: '7', isActive: true },
        { label: '30 days', value: '30', isActive: true },
      ],
      multiple: false,
    },
    occasionsFilterParams: {
      filterParamName: 'occasion',
      listParamName: 'occasions',
      title: 'Occasions',
    },
    vibesFilterParams: {
      filterParamName: 'vibes',
      listParamName: 'vibes',
      title: 'Vibes',
    },
    patternsFilterParams: {
      filterParamName: 'patterns',
      listParamName: 'patterns',
      title: 'Patterns',
    },
    priceFilterParams: {},
    chipsFilterParams: {},
    onRendered: () => {},
    onCloseClick: () => {},
    onApplyClick: () => {},
    onOpen: () => {},
    onClose: () => {},
    onOpened: () => {},
    onClosed: () => {},
    headerClearAllText: 'Clear all',
    styleFilterActionLabel: 'Clear',
    useChips: true,
    scrollContainer: null,
    showResultButtonLeft: 'Show ',
    showResultButtonRight: ' Results',
    showResultButtonSvg: ``,
    // showResultButtonSvg: `
    //   <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path d="M12.6518 0.767578L11.8563 1.56306L15.2312 4.93795H0.5625V6.06299H15.2311L11.8563 9.43778L12.6518 10.2333L17.3847 5.50042L12.6518 0.767578Z" fill="white"/>
    //   </svg>
    // `,
    title: 'Filters',
  };

  constructor(params) {
    super(params);

    this.container.classList.add('CollapsibleFilters-container');

    this.mainElement = document.createElement('div');

    if (params.filters) {
      this.params.filters = params.filters;
    }

    this.currentlyOpenedFilters = [];
  }

  didMount() {
    this.main.addIgnoredParams(['cfo']);
    setTimeout(() => {
      for (const filter of this.params.filters) {
        if (typeof this[`mount${upperFirst(filter)}Widget`] === 'function') {
          this[`mount${upperFirst(filter)}Widget`]();
        }
        else {
          this.mountWidget(filter);
        }
      }

      if (this.params.useChips)
        this.mountWidget('chips');

      this.renderFilterValues();
      this.renderFilterActions();

      const collapsibles = document.querySelectorAll('.Collapsible');
      const savedOpenedFilters = this.getSavedOpenedFilters();
      let filtersOpenByDefault = this.params.filtersOpenByDefault;
      if (window.innerWidth <= 768 && !isEmpty(this.params.filtersOpenByDefaultMobile)) {
        filtersOpenByDefault = this.params.filtersOpenByDefaultMobile;
      }
      filtersOpenByDefault = !isEmpty(savedOpenedFilters) ? savedOpenedFilters : filtersOpenByDefault;
      this.saveOpenedFilters(filtersOpenByDefault);
      collapsibles.forEach(collapsible => {
        const collapsibleName = collapsible.getAttribute('data-filter');
        if (filtersOpenByDefault.includes(collapsibleName)) {
          const content = collapsible.querySelector('.Collapsible-content');
          collapsible.classList.add('open');
          content.style.maxHeight = "none";
          if (typeof this.params.onCollapsibleOpened === 'function')
            this.params.onCollapsibleOpened(collapsibleName)
        }
      });

      const scrollContainer =
        this.params.scrollContainer
          ? this.params.scrollContainer
          : window.innerWidth <= 768
            ? this.container.querySelector('.CollapsibleFilters-Foldable')
            : this.container;

      const header = this.container.querySelector('.CollapsibleFilters-header')
      if (scrollContainer) {
        scrollContainer.addEventListener('scroll', (e) => {
          const container = e.target;
          const containerRect = container.getBoundingClientRect();
          const containerOffsetY = containerRect.y;
          collapsibles.forEach((collapsible, index) => {
            const filterName = collapsible.getAttribute('data-filter');
            if (this.params.stickyHeaders.includes(filterName) && collapsible.classList.contains('open')) {
              const { y } = collapsible.getBoundingClientRect();
              const relativeY = y - containerOffsetY - (header ? header.offsetHeight : 0);
              if (relativeY < 0) {
                collapsible.classList.add('is-sticky');
              }
              else {
                collapsible.classList.remove('is-sticky');
              }
            }
          });
        });
      }

      if (typeof this.params.onApplyClick === 'function') {
        const applyButton = this.container.querySelector('#collapsible-apply-button');
        if (applyButton) {
          applyButton.addEventListener('click', () => {
            this.params.onApplyClick();
            this.close();
          });
        }
      }

      const clearButton = document.getElementById('collapsible-clear-button'); 
      if (clearButton) {
        clearButton.addEventListener('click', this.handleClearAll);
      }

      const closeButton = document.getElementById('collapsible-close-button');
      if (closeButton) {
        closeButton.addEventListener('click', () => this.close());
      }

      const externalOpener = this.params.externalOpener && document.querySelector(this.params.externalOpener);
      if (externalOpener) {
        externalOpener.addEventListener('click', (e) => {
          e.stopPropagation();
          e.preventDefault();
          this.open();
        });
      }

      const totalFoundContainer = document.getElementById('collapsible-total-found');
      if (totalFoundContainer) {
        this.main.addWidget(
          this.main.widgets.TotalFound({
            container: '#collapsible-total-found',
          })
        );
      }

      this.backdrop = document.getElementById('collapsible-backdrop');
      if (this.backdrop)
        this.backdrop.addEventListener('click', () => this.close());

      if (typeof this.params.onRendered === 'function') {
        this.params.onRendered();
      }

      // Set the height
      // if (window.innerWidth <= 768) {
      //   console.log(window.innerHeight);
      //   console.log(this.mainElement);
      //   this.mainElement.style.height = window.innerHeight - 75;
      // }
    }, 100);
  }

  didUpdate(prevState) {
    this.renderFilterValues(prevState);
  }

  getSavedOpenedFilters() {
    return this.state?.filter?.cfo || null;
  }

  saveOpenedFilters(filters) {
    filters = uniq(filters);
    this.currentlyOpenedFilters = filters;
    this.main.updateSearchQuery({
      ...this.state.filter,
      cfo: filters,
    });
  }

  handleClearAll = () => {
    const filter = this.state.filter;
    const newFilter = {};

    Object.keys(filter).forEach(key => {
      if (['query', 'cfo'].includes(key)) return;
      newFilter[key] = null;
    });

    this.main.updateSearchQuery({
      ...newFilter,
    });

    EventEmitter.emit('txtSidebarClearAllClick');
  }

  handlePresetsRendered = (presets) => {
    const presetsCollapsible = this.container.querySelector('[data-filter="presets"]');
    if (presets.length > 0) {
      presetsCollapsible.style.display = 'block';
    }
    else {
      presetsCollapsible.style.display = 'none';
    }
  }

  handlePatternsRendered = (types) => {
    const patternsCollapsible = this.container.querySelector('[data-filter="patterns"]');
    if (types.length > 0) {
      patternsCollapsible.style.display = 'block';
    }
    else {
      patternsCollapsible.style.display = 'none';
    }
  }

  handleMaterialsRendered = (types) => {
    const patternsCollapsible = this.container.querySelector('[data-filter="materials"]');
    if (types.length > 0) {
      patternsCollapsible.style.display = 'block';
    }
    else {
      patternsCollapsible.style.display = 'none';
    }
  }

  handleTextMaterialsRendered = (materials) => {
    const collapsible = this.container.querySelector('[data-filter="textMaterials"]');
    if (materials.length > 0) {
      collapsible.style.display = 'block';
    }
    else {
      collapsible.style.display = 'none';
    }
  }

  handleOccasionsRendered = (occasions) => {
    const occasionsCollapsible = this.container.querySelector('[data-filter="occasions"]');
    if (occasions.length > 0) {
      occasionsCollapsible.style.display = 'block';
    }
    else {
      occasionsCollapsible.style.display = 'none';
    }
  }

  handleVibesRendered = (vibes) => {
    const vibesCollapsible = this.container.querySelector('[data-filter="vibes"]');
    if (vibes.length > 0) {
      vibesCollapsible.style.display = 'block';
    }
    else {
      vibesCollapsible.style.display = 'none';
    }
  }

  handleWashRendered = (washTypes) => {
    const washCollapsible = this.container.querySelector('[data-filter="wash"]');
    if (washTypes.length > 0) {
      washCollapsible.style.display = 'block';
    }
    else {
      washCollapsible.style.display = 'none';
    }
  }

  handleBrandRendered = (brands) => {
    const brandCollapsible = this.container.querySelector('[data-filter="brand"]');
    if (brands.length > 0) {
      brandCollapsible.style.display = 'block';
    }
    else {
      brandCollapsible.style.display = 'none';
    }
  }

  handleBodyPartsRendered = (isEmpty) => {
    const collapsible = this.container.querySelector('[data-filter="bodyParts"]');
    if (!isEmpty) {
      collapsible.style.display = 'block';
    }
    else {
      collapsible.style.display = 'none';
    }
  }

  handleSizeRendered = (sizes) => {
    const sizeCollapsible = this.container.querySelector('[data-filter="size"]');
    if (sizes.length > 0) {
      sizeCollapsible.style.display = 'block';
    }
    else {
      sizeCollapsible.style.display = 'none';
    }
  }

  handlePriceRendered = (range) => {
    const collapsible = this.container.querySelector('[data-filter="price"]');
    if (range.min === 0 && range.max === 0) {
      collapsible.style.display = 'none';
    }
    else {
      collapsible.style.display = 'block';
    }
  }

  handleSubcategoriesRendered = (subcategories) => {
    const collapsible = this.container.querySelector('[data-filter="subcategories"]');
    const title = collapsible.querySelector('.Collapsible-header-title h3');
    title.innerHTML = findLabelByLng(
      this.main.categories[this.state.filter.categoryId].label,
      this.state.config.lng
    ).replace('Women', '').replace('Men', '');

    if (subcategories.length > 0) {
      collapsible.style.display = 'block';
    }
    else {
      collapsible.style.display = 'none';
    }
  }

  mountWidget(filter) {
    const widgetName = WIDGETS_MAP[filter];
    if (widgetName && this.main.widgets[widgetName]) {
      this.main.addWidget(
        this.main.widgets[widgetName]({
          container: `#collapsible-${filter}-filter`,
          ...this.params[`${filter}FilterParams`],
          ...(filter === 'textMaterials' ? { onRendered: this.handleTextMaterialsRendered } : {}),
          ...(filter === 'brand' ? { onRendered: this.handleBrandRendered } : {}),
          ...(filter === 'size' ? { onRendered: this.handleSizeRendered } : {}),
          ...(filter === 'price' ? { onRendered: this.handlePriceRendered } : {}),
          ...(filter === 'bodyParts' ? { onRendered: this.handleBodyPartsRendered } : {}),
          ...(filter === 'occasions' ? { onRendered: this.handleOccasionsRendered } : {}),
          ...(filter === 'vibes' ? { onRendered: this.handleVibesRendered } : {}),
          ...(filter === 'patterns' ? { onRendered: this.handlePatternsRendered } : {}),
        })
      );
    }
  }

  getColorsFilterValue(prevFilter, filter) {
    const { colors: prevColors } = prevFilter || {};
    const { colors: colors = [] } = filter;
    if (!prevColors || !isEqual(prevColors, colors)) {
      if (!colors) return '';
      return colors.map(color => upperFirst(color)).join(', ');
    }
    else if (colors.length === 0) {
      if (!Array.isArray(colors)) return '';
      return '';
    }
    return false;
  }

  getPriceFilterValue(prevFilter, filter) {
    const { price: prevPrice } = prevFilter || {};
    const { price } = filter;
    if (!prevPrice || prevPrice !== price) {
      if (!price || price === '0-') return '';
      const [low, high] = price.split('-');
      return [
        low ? formatCurrency(parseInt(low), this.params.priceFilterParams.currency || {}) : '',
        high ? formatCurrency(parseInt(high), this.params.priceFilterParams.currency || {}) : ' or more',
      ].join(high ? '-' : '');
    }
    return false;
  }

  getBrandFilterValue(prevFilter, filter) {
    const { brands: prevBrands } = prevFilter || {};
    const { brands = [] } = filter;
    if (!prevBrands || !isEqual(prevBrands, brands)) {
      if (!Array.isArray(brands)) return '';
      return brands.join(', ');
    }
    else if (brands.length === 0) {
      return '';
    }
    return false;
  }

  getTextMaterialsFilterValue(prevFilter, filter) {
    const { materials: prevMaterials } = prevFilter || {};
    const { materials = [] } = filter;
    if (!prevMaterials || !isEqual(prevMaterials, materials)) {
      if (!Array.isArray(materials)) return '';
      return materials.join(', ');
    }
    else if (materials.length === 0) {
      return '';
    }
    return false;
  }

  getSizeFilterValue(prevFilter, filter) {
    const { sizes: prevSizes } = prevFilter || {};
    const { sizes = [] } = filter;
    if (!prevSizes || !isEqual(prevSizes, sizes)) {
      if (!Array.isArray(sizes)) return '';
      return sizes.join(', ');
    }
    else if (sizes.length === 0) {
      return '';
    }
    return false;
  }

  getDiscountsFilterValue(prevFilter, filter) {
    const items = this.params.discountsFilterParams.items;
    const { discount: prevDiscounts } = prevFilter || {};
    const { discount: discounts = [] } = filter;
    if (!prevDiscounts || !isEqual(prevDiscounts, discounts)) {
      if (!discounts) return '';

      return discounts.map(value => {
        const item = find(items, { value });
        if (item) return item.label;
        return value;
      }).join(', ');
    }
    else if (discounts.length === 0) {
      return '';
    }
    return false;
  }

  getShippingFilterValue(prevFilter, filter) {
    const items = this.params.shippingFilterParams.items;
    const { shipping: prevShippings } = prevFilter || {};
    const { shipping: shippings = [] } = filter;
    if (!prevShippings || !isEqual(prevShippings, shippings)) {
      if (!shippings) return '';

      return shippings.map(value => {
        const item = find(items, { value });
        if (item) return item.label;
        return value;
      }).join(', ');
    }
    else if (shippings.length === 0) {
      return '';
    }
    return false;
  }

  renderFilterValues(prevState = {}) {
    if (!this.params.displaFilterValues) return;

    const { filter: prevFilter = {} } = prevState;
    const { filter } = this.state;
    for (const filterName of this.params.filters) {
      if (typeof this[`get${upperFirst(filterName)}FilterValue`] === 'function') {
        const value = this[`get${upperFirst(filterName)}FilterValue`](prevFilter, filter);
        if (typeof value === 'string') {
          const titleElement = document.getElementById(`${filterName}-filter-value`);
          if (titleElement) titleElement.innerHTML = value.length ? `(${value})` : value;
        }
      }
    }
  }

  getNewArrivalsFilterValue(prevFilter, filter) {
    const { newArrivals: prevNewArrivals } = prevFilter || {};
    const { newArrivals = [] } = filter;
    if (!isEqual(prevNewArrivals, newArrivals)) {
      return newArrivals?.length > 0 ? `${newArrivals} day${parseInt(newArrivals) > 1 ? 's' : ''}` : '';
    }
    return false;
  }

  getStyleFilterAction() {
    return this.params.styleFilterActionLabel;
  }

  handleStyleFilterActionClick = (e) => {
    e.stopPropagation();
    const filter = this.main.getFilter();
    const { parts } = this.main.categories[filter.categoryId];
    const params = filter.params;

    parts.forEach(part => {
      params[part.name] = 'all';

      if (!part.isPublished) params[part.name] = part.disabledValue;
    });
    this.setFilter({
      presetIndex: null,
      occasion: null,
      params,
    });
  }

  renderFilterActions(prevState = {}) {
    const { filter: prevFilter = {} } = prevState;
    const { filter } = this.state;
    for (const filterName of this.params.filters) {
      const actionName = upperFirst(filterName);
      if (typeof this[`get${actionName}FilterAction`] === 'function') {
        const label = this[`get${actionName}FilterAction`](prevFilter, filter);
        if (typeof label === 'string') {
          const actionElement = document.getElementById(`${filterName}-filter-action`);
          if (actionElement) {
            actionElement.innerHTML = label;
            actionElement.addEventListener('click', this[`handle${actionName}FilterActionClick`]);
          }
        }
      }
    }
  }

  renderCollapsible(filter, label = null) {
    const { lng } = this.state.config;
    let htmlContent = `<div id="collapsible-${filter}-filter"></div>`;
    if (filter === 'style') {
      htmlContent = '<div id="collapsible-visual-filter"></div>' + htmlContent;

      if (this.params.usePresetFilter) {
        htmlContent = '<div id="collapsible-editors-pick-filter"></div>' + htmlContent;
      }
    }

    const collapsible = document.createElement('div');
    collapsible.className = `Collapsible`;
    collapsible.setAttribute('data-filter', filter);
    collapsible.innerHTML = `
      <div class="Collapsible-header">
        <span class="plus"></span>
        <div class="Collapsible-header-title">
          <h3>${findLabelByLng(label || this.params.filterLabels[filter], lng)}</h3>
          <span class="filter-value" id="${filter}-filter-value"></span>
          <span class="filter-spreader"></span>
          <span class="filter-action" id="${filter}-filter-action"></span>
        </div>
      </div>
      <div class="Collapsible-content">
        ${htmlContent}
      </div>
    `;

    collapsible.querySelector('.Collapsible-header').addEventListener('click', (e) => {
      const content = collapsible.querySelector('.Collapsible-content');
      if (collapsible.classList.contains('open')) {
        collapsible.classList.remove('open');
        collapsible.classList.remove('is-sticky');
        content.style.maxHeight = content.scrollHeight + "px";
        setTimeout(() => {
          content.style.maxHeight = null;

          const transitionend = () => {
            content.removeEventListener('transitionend', transitionend);

            if (typeof this.params.onCollapsibleClosed === 'function')
              this.params.onCollapsibleClosed(filter);
          }
          content.addEventListener('transitionend', transitionend);
        }, 0);
        this.saveOpenedFilters(this.currentlyOpenedFilters.filter(f => f !== filter));
      }
      else {
        collapsible.classList.add('open');
        collapsible.classList.add('is-animating');
        content.style.maxHeight = content.scrollHeight + "px";

        const transitionend = () => {
          collapsible.classList.remove('is-animating');
          content.style.maxHeight = "none";
          content.removeEventListener('transitionend', transitionend);

          if (typeof this.params.onCollapsibleOpened === 'function')
            this.params.onCollapsibleOpened(filter);

          // if (!isInViewport(content)) {
          //   content.scrollIntoView();
          // }
        }
        content.addEventListener('transitionend', transitionend);
        this.saveOpenedFilters([...this.currentlyOpenedFilters, filter]);
      }
    });

    return collapsible;
  }

  open() {
    const slider = this.mainElement;
    slider.style.transform = `translate3d(0, 0, 0)`;
    this.backdrop.classList.remove('is-hidden');

    if (typeof this.params.onOpen === 'function') {
      this.params.onOpen();
    }

    const onOpened = () => {
      document.body.style.overflow = 'hidden';
      slider.removeEventListener('transitionend', onOpened);

      // EventEmitter.emit('collapsibleMobileOpened');

      if (typeof this.params.onOpened === 'function') {
        this.params.onOpened();
      }
    };
    slider.addEventListener('transitionend', onOpened);
  }

  close() {
    const slider = this.mainElement;
    const { height } = slider.getBoundingClientRect();
    const translation = height;

    slider.style.transform = `translate3d(0, ${translation}px, 0)`;

    this.backdrop.classList.add('is-hidden');

    if (typeof this.params.onClose === 'function') {
      this.params.onClose();
    }

    const onClosed = () => {
      document.body.style.overflow = 'visible';
      slider.removeEventListener('transitionend', onClosed);

      // EventEmitter.emit('collapsibleMobileClosed');

      if (typeof this.params.onClosed === 'function') {
        this.params.onClosed();
      }
    };
    slider.addEventListener('transitionend', onClosed);
  };

  render() {
    if (this.params.useChips) {
      this.mainElement.insertAdjacentHTML('beforeend', `
        <div class="Collapsible-header-chips" id="collapsible-chips-filter"></div>
      `);
    }

    this.mainElement.classList.add('CollapsibleFilters');
    for (const filter of this.params.filters) {
      if (!filter) continue;
      this.mainElement.appendChild(this.renderCollapsible(filter));
    }

    if (this.params.isFoldable) {
      this.mainElement.classList.add('CollapsibleFilters-Foldable');
    }

    if (this.params.useHeader) {
      this.mainElement.insertAdjacentHTML('afterbegin', `
        <div class="CollapsibleFilters-header">
          <div class="CollapsibleFilters-header-actions">
            <h3>${this.params.title}</h3>
            <button id="collapsible-clear-button">${this.params.headerClearAllText}</button>
          </div>
          <div id="collapsible-close-button" class="CollapsibleFilters-header-close">
            <img src="${closeSvg}" alt="" />
          </div>
        </div>
      `);
    }

    if (this.params.useFooter) {
      this.mainElement.insertAdjacentHTML('beforeend', `
        <div class="CollapsibleFilters-footer">
          <button id="collapsible-apply-button" className="CollapsibleFilters-footer-button">
            ${this.params.showResultButtonLeft}<span id="collapsible-total-found"></span>${this.params.showResultButtonRight}
            ${this.params.showResultButtonSvg}
          </button>
        </div>
      `);      
    }
    this.container.insertAdjacentHTML('afterbegin', `
      <div id="collapsible-backdrop" class="CollapsibleFilters-backdrop${!this.params.isOpenInitialy ? ' is-hidden' : ''}"></div>
    `);

    if (!this.params.isOpenInitialy && this.params.isFoldable) {
      this.mainElement.style.transform = `translate3d(0, 100vh, 0)`;
    }

    this.mainElement.setAttribute('data-category-id', this.state.filter.categoryId);

    return this.mainElement;
  }
}

export default (params) => {
  return new CollapsibleFilters(params);
};
