import { SearchBar as BaseSearchBar } from '@yesplz/textsearch/src/widgets/SearchBar';
import logoImage from '../assets/logo.svg';
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/css';
import take from 'lodash/take';


const LAYOUT_TEMPLATE = `
<div class="yesplz-text-search-backdrop"></div>
<div class="yesplz-text-search-main">
  <div class="yesplz-text-search-container">
    <button class="yesplz-text-search-close-button"></button>
    <img src="${logoImage}" alt="" class="yesplz-text-search-logo" />
    <div class="yesplz-text-search-header">
      <div class="yesplz-text-search-bar-container">
        <form class="yesplz-text-search-form">
          <input type="text" placeholder="Search" value="" max />
          <button class="submit" type="submit">Search</button>
          <button id="yesplz-text-search-form-clear" class="clear" type="button">clear</button>
        </form>
      </div>
      <div class="yesplz-text-search-menu-container">
        <div class="yesplz-menu sale-container">
          <ul>
            <li class="sale" data-menu-value="sale">Sale Only</li>
          </ul>
        </div>
        <div class="yesplz-menu">
          <ul>
            <li data-menu-value="all" class="is-active">All</li>
            <li data-menu-value="WOMEN">Women</li>
            <li data-menu-value="MEN">Men</li>
            <li data-menu-value="K-BEAUTY">K-Beauty</li>
            <li data-menu-value="LIFE">Life</li>
          </ul>
        </div>
        <div class="yesplz-suggestions">
          <h5>Top Suggestions</h5>
          <ul id="yesplz-suggested-queries"></ul>
        </div>
      </div>
    </div>
    <div class="yesplz-text-search-popular-queries">
      <h4>Popular Searches</h4>
      <ul id="yesplz-popular-queries"></ul>
    </div>
    <div class="yesplz-text-search-footer">
      <button id="see-results">See All Results</button>
    </div>
    <div class="yesplz-text-search-empty-results">
      <h3>Unfortunately,<br />there are no results matching your request.</h3>
    </div>
    <div class="yesplz-text-search-products">
      <h3>Recommended products</h3>
      <div id="yesplz-text-search-products"></div>
    </div>
  </div>
</div>
`;

const SLIDER_TEMPLATE = `
<div class="swiper-container product-list">
  <ul class="swiper-wrapper">
  </ul>
</div>
`;

export class SearchBar extends BaseSearchBar {
  constructor(params) {
    params.templates = params.templates || {};

    if (!params.templates.layout) {
      params.templates.layout = LAYOUT_TEMPLATE;
    }
    params.templates.list = SLIDER_TEMPLATE;

    params.overflowContainer = document.querySelector('#wrap') || document.body;

    super(params);

    this.swiper = null;
  }

  loadResults() {
    const query = this.mainInput.value;
    if (query.length > 0) {
      this.clearButton.classList.add('is-active');
      this.mainElement.classList.remove('no-results');
      const suggestionPromise = Promise.all([
        (
          this.params.useBrandSuggestions
            ? this.main.fetchBrandsSuggestions(query)
            : Promise.resolve([])
        ),
        this.main.fetchTextAutocomplete({
          query,
          [this.params.categoryParamName]: this.topCategory,
          sale: this.sale,
        }),
      ]);
      this.suggestionPromise = suggestionPromise;
      suggestionPromise
        .then(([brands, texts]) => {
          const suggestionsMax = window.innerWidth <= 768 ? 4 : 8;
          const brandSuggestions = take(brands, texts.length > (suggestionsMax / 2) ? (suggestionsMax / 2) : suggestionsMax - texts.length).map(brand => ({ suggestion: brand.brandName, url: brand.brandUrl, type: 'brand' }));
          return [
            ...brandSuggestions,
            ...(brandSuggestions.length > 0 ? [{ suggestion: ' ', type: 'separator' }] : []),
            ...take(texts, brandSuggestions.length > (suggestionsMax / 2) ? (suggestionsMax / 2) : suggestionsMax - brandSuggestions.length)
          ];
        })
        .then(suggested => {
          if (this.suggestionPromise === suggestionPromise) {
            this.renderSuggestedQueries(suggested, query);
          }
        })
        .catch(error => {
          console.error('text suggestion error', error);
        });

      const searchPromise = this.main.fetchTextSearch({
        query,
        sort: 'popular',
        [this.params.categoryParamName]: this.topCategory,
        sale: this.sale,
        limit: 5,
        preview: true,
      })
      this.searchPromise = searchPromise;
      searchPromise
        .then(data => {
          if (this.searchPromise === searchPromise) {
            this.productsContainer.style.display = 'none';

            window.requestAnimationFrame(() => {
              this.productsContainer.innerHTML = this.listTemplate();
              const listContainer = this.productsContainer.querySelector('ul');
              data?.results?.forEach(({ product }) => {
                this.renderProduct(product, listContainer);
              });

              if (typeof this.params.onProductsRendered === 'function') {
                this.params.onProductsRendered(data.results);
              }

              if (data?.results && data?.results?.length > 0) {
                this.mainElement.classList.remove('empty-results');
                if (window.innerWidth < 768) {
                  setTimeout(() => {
                    this.productsContainer.style.display = 'block';
                    this.swiper = new Swiper('.swiper-container', {
                      slidesPerView: window.innerWidth >= 768 ? 5 : 2.3,
                      loop: true, // 루프 모드 사용 여부
                      initialSlide: 0,
                      freeMode: true,
                      spaceBetween: 10,
                    });
                  }, 100);
                }
                else {
                  this.productsContainer.style.display = 'block';
                }
              }
              else {
                this.mainElement.classList.add('empty-results');
              }
            });
          }
        })
        .catch(error => {
          console.error('text search error', error);
        });
    }
    else {
      this.reset();
    }
  }

  renderProduct(product, listContainer) {
    listContainer = document.querySelector(".swiper-wrapper");
    let productHtml = this.productTemplate({
      ...product,
      srcUrl: this.getProductUrl(product),
      frontImg: product.frontImgSrc,
      name: product.productName,
      brand: product.brand || product.brandName,
      isSale: !!(product.originalPrice && product.originalPrice !== product.salePrice),
      originalPrice: this.formatCurrency(product.originalPrice),
      price: this.formatCurrency(product.salePrice),
    });

    if (typeof this.params.onBeforeProductRendered === 'function') {
      productHtml = this.params.onBeforeProductRendered(productHtml, product);
    }

    const html = `<li class="swiper-slide product-item">${productHtml}</li>`;
    listContainer.insertAdjacentHTML("beforeend", html);
    listContainer.lastElementChild.querySelectorAll('a')?.forEach(a => {
      a.addEventListener('click', () => {
        EventEmitter.emit('txtSearchBarProductClick', product.productId);
      });
    });

    document.querySelector(".swiper-container").style.visibility = "visible";
  }
}



export default (params) => {
  return new SearchBar(params);
};
