import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import { findLabelByLng } from '@yesplz/core';
import Widget from '../modules/Widget';
import EventEmitter from '../modules/EventEmitter';
// import isParamsMatchPreset from '../helpers/isParamsMatchPreset';
import { FABRIC_COLORS, COLOR_LABELS } from '@yesplz/visualfilter/src/consts';

import clearSvg from '@yesplz/core-models/assets/svg/reset-icon.svg';

class ColorFilter extends Widget {
  defaultParams = {
    resetImage: clearSvg,
    displayLabels: true,
  };

  constructor(params) {
    super(params);

    this.element = document.createElement('div');
    this.element.className = 'ColorPallete';
    this.colorElements = {};

    this.params.colors = {
      ...(this.params.colors ?? FABRIC_COLORS),
      clear: '',
    };
  }

  didMount() {
    this.updateColors();
  }

  didUpdate(prevState) {
    if (!isEqual(prevState.search?.filter?.colors, this.state.search?.filter?.colors)) {
      this.updateColors();
    }
    else if (
      !isEqual(prevState.filter.colors, this.state.filter.colors)
    ) {
      const prevColors = prevState.filter.colors;
      const colors = this.state.filter.colors;
      const addedItems = difference(colors, prevColors);
      const removedItems = difference(prevColors, colors);
      addedItems.forEach(val => this.colorElements[val].classList.add('active'));
      removedItems.forEach(val => this.colorElements[val].classList.remove('active'));
    }
  }

  handleLabelClick = (colorName) => {
    if (colorName === 'clear') {
      this.main.resetList('colors');
    }
    else {
      this.main.toggleList('colors', colorName);
      EventEmitter.emit(
        this.state.filter.colors.includes(colorName)
          ? 'txtSidebarColorApplied'
          : 'txtSidebarColorRemoved',
        colorName
      );
    }
  }

  updateColors() {
    const { colors: availableColors = {} } = this.state.search?.filter || {};

    for (const colorName in this.params.colors) {
      const isAvailable = !!(availableColors[colorName] && availableColors[colorName].isActive) || colorName === 'clear';
      if (!isAvailable) {
        this.colorElements[colorName].classList.add('disabled');
      }
      else {
        this.colorElements[colorName].classList.remove('disabled');
      }
    }
  }

  renderColors() {
    const { colors: currentColors } = this.state.filter;
    const { lng } = this.state.config;
    this.element.innerHTML = '';
    this.colorElements = {};
    const colorButtons = this.params.colors;

    for (const colorName in colorButtons) {
      const color = FABRIC_COLORS[colorName];
      const labelElement = document.createElement('button');
      const isActive = currentColors && currentColors.includes(colorName);

      if (colorName === 'clear') {
        labelElement.innerHTML = `<div class="ColorButton-color-wrapper">
          <img src="${this.params.resetImage}" alt="" />
        </div>
        ${this.params.displayLabels ? `<span>${findLabelByLng(COLOR_LABELS['all'], lng)}</span>` : ''}`;
      }
      else {
        const styleProp = `style="background-${color[0] !== '#' ? 'image' : 'color'}: ${color};"`;
        labelElement.innerHTML =
          `
          <div class="ColorButton-color-wrapper">
            <div
              class="ColorButton-color"
              ${styleProp}
            ></div>
            <div class="ColorButton-mask"></div>
          </div>
          ${this.params.displayLabels ? '<span>' + findLabelByLng(COLOR_LABELS[colorName] ? COLOR_LABELS[colorName] : colorName, lng) + '</span>' : ''}
          `;
      }

      labelElement.className = 'ColorButton ColorButton-' + colorName + (
        isActive ? ' active' : ''
      );
      this.colorElements[colorName] = labelElement;
      const wrapperElement = document.createElement('div');
      wrapperElement.appendChild(labelElement);
      wrapperElement.classList.add('ColorButton-wrapper');
      this.element.appendChild(wrapperElement);
      labelElement.addEventListener('click', () => {
        this.handleLabelClick(colorName);
      });
    }
  }

  render() {
    const container = document.createElement('div');
    const title = document.createElement('h3');
    title.innerText = this.params.title ? this.params.title : 'Colors';
    container.appendChild(title);
    this.renderColors();
    container.appendChild(this.element);
    return container;
  }
}

export default (params) => {
  return new ColorFilter(params);
};
