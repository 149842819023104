import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { extendedCategories, fetchConfiguration } from '@yesplz/visualfilter/src/extended-configuration';
import VisualFilter from '@yesplz/visualfilter';
import Product from '@yesplz/product';
import { TextSearch } from '@yesplz/textsearch';
import SearchBar from './wconcept/widgets/SearchBar';
import internalConfiguration from './configuration.json';
import internalTreeMenu from './tree-menu.json';
import findLabelByLng from './findLabelByLng';
import sortCategories from './sortCategories';
import formatCurrency from './formatCurrency';
import fillPublishedListItem from './fillPublishedListItem';
import { fillCategoriesByParent, cleanChildrenByParents } from './fillCategoriesByParent';
import aiStylist from './AiStylist';
import buildTree from './buildTree';

import './wconcept/search_bar.scss';

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

function getRetailerCategories() {
  const categories = Object.entries(internalConfiguration).reduce((categories, [categoryId, category]) => {
    if (category.isPublished) {
      if (!category.vibesList) category.vibesList = [];
      categories[categoryId] = {
        ...extendedCategories[category.baseCategory || categoryId],
        ...category,
        presetsList: category.presetsList || [],
        occasionsList: category.occasionsList || [],
        vibesList: category.vibesList || [],
        moodsList: category.moodsList || [],
        patterns: category.patterns || [],
        materials: category.materials || [],
      };
    }
    return categories;
  }, {});

  return sortCategories(categories);
}

const visualfilter = (params = {}) => {
  if (!params.categories) {
    params.categories = getRetailerCategories();
  }
  if (!params.menu) {
    params.menu = internalTreeMenu;
  }
  return new VisualFilter(params);
};

const product = (params = {}) => {
  return new Product(params);
};

const stylefilter = visualfilter;
const StyleFilter = VisualFilter;

const textsearch = (params = {}) => {
  params.clientAliases = {
    'categories': 'retailer_category',
  };

  if (!params.initialFilter) {
    params.initialFilter = {};
  }

  if (!params.initialFilter.sort) {
    params.initialFilter.sort = 'popular';
  }

  const ts = new TextSearch(params);

  ts.widgets['SearchBar'] = SearchBar;

  return ts;
};

export {
  stylefilter,
  StyleFilter,
  visualfilter,
  product,
  VisualFilter,
  Product,
  fetchConfiguration,
  getRetailerCategories,
  findLabelByLng,
  formatCurrency,
  sortCategories,
  fillPublishedListItem,
  fillCategoriesByParent,
  cleanChildrenByParents,
  aiStylist,
  buildTree,
  textsearch,
  TextSearch,
};
